import appota from "./appota.png";
import beeketing from "./beeketing.png";
import DoAnhTuan from "./DoAnhTuan.png";
import kk from "./kk.png";
import KoichiSaito from "./KoichiSaito.png";
import phay from "./phay.png";
import TruongManhQuan from "./TruongManhQuan.png";
import ArrowLeft from "./fi_chevron-left.png";
import ArrowRight from "./fi_chevron-right.png";

export const ImageAbout = {
  appota,
  beeketing,
  DoAnhTuan,
  kk,
  KoichiSaito,
  phay,
  TruongManhQuan,
  ArrowRight,
  ArrowLeft,
};
