import { ImagePartner } from '../assets/partner'

const name = [
    `AWS`,
    `Google Cloud`,
    `Miro`,
    `Hubspot for Startups`,
    `Zendesk`,
    `Monday.com`,
    `Stripe`,
    `Docsend`,
    `Techstars`,
    `Wework`,
    `Notion`,
]

const link = [
    `https://aws.amazon.com/vi/`,
    `https://cloud.google.com/`,
    `https://miro.com/index/`,
    `https://www.hubspot.com/startups`,
    `https://www.zendesk.com/startups/`,
    `https://monday.com/`,
    `https://stripe.com/`,
    `https://www.docsend.com/`,
    `https://www.techstars.com/`,
    `https://www.wework.com/vi-VN`,
    `https://www.notion.so/`,
]

const des = [
    `Amazon Web Services provides Founder Institute Founders with $10K USD in AWS credits valid for 1 year and more. (Offer is country-specific.)`,
    `The Google Cloud Platform is offering up to $100,000 USD to qualified Founders. If you are a fairly new startup, a new Spark program is being instituted allotting 20,000 USD for Founders. Only for publicly funded companies.`,
    `FI Graduates can get $1000 in free credits by Miro, the most intuitive online collaborative whiteboard platform for distributed teams.`,
    `FI Alumni can apply for HubSpot's accelerator + 90% off HubSpot's Entire Growth Suite of Marketing, Sales, and Customer Service Software for Eligible Seed-Stage Startups.`,
    `FI Startups can get 6 months free of Zendesk Support and Sales CRM.`,
    `Monday.com, a Work OS that provides you with all of the no-code building blocks to shape your workflows in your way, provides founders with:<br/>(1) 100% free of charge monday.com account for the first 6 months<br/>(2) 25% discount for the 1st year contract`,
    `Stripe’s platform enables companies around the world to accept payments, expand globally and create new revenue streams. FI graduates are eligible for $20,000 of fee-free payment processing.`,
    `DocSend is a secure document sharing and tracking solution that helps professionals build relationships and close business. DocSend is offering up to 90% discount from Standard or Advanced subscriptions for new, FI affiliated, users.`,
    `FI graduates can receive exclusive support during the application process for Techstars.`,
    `WeWork is revolutionizing the way people and companies work. Get flexible workspaces, agile services, and leading technologies to move your business forward. WeWork offers FI Startups with 10% discount.`,
    `Notion is an application that provides components such as notes, databases, kanban boards, wikis, calendars and reminders. FI Startups can apply to get up to $1000 in credit to try it for free.`,
]

export const PartnerData = name.map((i, index) => {
    return { name: i, link: link[index], image: ImagePartner[index], des: des[index] };
}).filter((i) => { return typeof i.image !== 'undefined' && i.image !== null });
