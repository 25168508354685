import { SliderPartner } from "./slider-partner-offers";

export function PartnerOffers() {
  return (
    <div  className="text-center py-16 relative">
      <h3 className="font-bold text-[40px] leading-[1.15] text-center text-[#676767]">
        <span className="text-Funding">Partner</span> Offers
      </h3>
      <p className="font-medium text-base text text-center text-[#676767] max-w-[76%] mx-auto mt-[47px]">
        TFI Founders have the right to access both FI Global Partners and TFI
        Local Partners' discounted and free services. More global partners can
        be found on FI site. Below is the list of some featured global partners
        and all local partners.
      </p>
      <SliderPartner />
      <div className="mx-auto container mt-12">
        <div className="flex items-center justify-center mx-auto">
          <a
            href="https://bit.ly/TFI-Partner"
            className="bg-btn px-6 py-4 font-bold text-white text-[28px] leading-[1] rounded-full  min-w-[303px]  text-center c"
            target="_blank"
            rel="noreferrer"
          >
            Apply to be a Partner
          </a>
        </div>
      </div>
    </div>
  );
}
