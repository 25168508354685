import { SliderNetwork } from "./slider-network";

export function Networks() {
  return (
    <div id="Network" className="text-center py-16 ">
      <h3 className="font-bold text-[40px] leading-[1.15] text-center text-[#676767]">
        <span className="text-Funding">Investors</span> Network
      </h3>
      <SliderNetwork />
    </div>
  );
}
