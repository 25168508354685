import { useRef, useState } from "react";
import { ImageAbout } from "../assets/about";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass, Navigation, Autoplay } from "swiper";


export function AboutTFI() {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [swiperRef, setSwiperRef] = useState<null | SwiperClass>(null);

  const data = [
    {
      img: ImageAbout.KoichiSaito,
      des: "I have been involved with the TFI program in its early days and every year, I see great improvements in terms of quality of founders, the ideas and solutions proposed, the pitch by the founders, and the quality of audience during demo days. It is no surprise to me to see the crowd we saw tonight, the most stellar yet, and all credits go to the belief and hard work of the TFI team, or should I say, the TFI Mafias.",
      name: "KOICHI SAITO",
      office: "Founding Partner KK Fund",
      stamp: ImageAbout.kk,
    },
    {
      img: ImageAbout.DoAnhTuan,
      des: "“TFI has given me the practical knowledge, skills, business sense, and the network I need to grow Appota to where it is today. I strongly encourage startups to join TFI, you can learn so much from it.”",
      name: "DO TUAN ANH",
      office: "CEO & Founder of Appota",
      stamp: ImageAbout.appota,
    },
    {
      img: ImageAbout.TruongManhQuan,
      des: "“If hadn't joined TFI, it would have been unlikely that there would have had no Beeketing today, or if yes, it would have gone very slowly. It is not wrong to say that TFI is a launch pad for technology startups like us.”",
      name: "TRUONG MANH QUAN",
      office: "CEO of Beeketing",
      stamp: ImageAbout.beeketing,
    },
  ];

  return (
    <div className="pt-16">
      <h3 className="font-bold text-[32px] sm:text-[40px] leading-[1.15] text-center text-[#676767]">
        What <span className="text-Funding">Alumni, Mentors and Investors</span> say about TFI
      </h3>

      <Swiper
        className="about relative"
        spaceBetween={10}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 5000,
        }}
        autoHeight={false}
        navigation={{
          enabled: true,
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onSwiper={setSwiperRef}
        modules={[Navigation, Autoplay]}
      >
        {data.map((i, index) => {
          return (
            <SwiperSlide key={`about-swiper-${index}`} className="px-4 md:px-[5%] pt-9 pb-16">
              <div className="build-us bg-white bg-opacity-70 rounded-2xl flex flex-col lg:flex-row p-6 sm:p-8 md:p-12 lg:p-16 gap-x-8 items-center">
                <div className="relative rounded-3xl lg:basis-[378px]">
                  <img src={i.img} alt={i.name} className="rounded-3xl overflow-hidden" />
                  <img src={ImageAbout.phay} alt={'"'} className="absolute z-10 -top-6 -right-11 hidden sm:block" />
                </div>
                <div className="flex-1 mt-6 lg:mt-0">
                  <p className="font-medium text-base sm:text-xl leading-normal">{i.des}</p>
                  <div className="flex flex-row mt-8 gap-x-4 items-center flex-wrap">
                    <img src={i.stamp} alt={`tamp-${i.name}`} />
                    <div className="">
                      <p className="text-[#4B4B4B] font-bold text-xl leading-[1.3]">{i.name}</p>
                      <p className="text-[#4B4B4B] font-bold text-xl leading-[1.3]">{i.office}</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
        <button
          ref={navigationPrevRef}
          className="absolute left-0 z-10 topNav"
          onClick={() => {
            if (swiperRef) {
              swiperRef.slidePrev();
            }
          }}
        >
          <img src={ImageAbout.ArrowLeft} alt="Left" />
        </button>
        <button
          ref={navigationNextRef}
          className="absolute right-0 z-10 topNav"
          onClick={() => {
            if (swiperRef) {
              swiperRef.slideNext();
            }
          }}
        >
          <img src={ImageAbout.ArrowRight} alt="Right" />
        </button>
      </Swiper>
    </div>
  );
}
