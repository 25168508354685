import Amazon from "./Amazon_logo.svg.png";
import Google from "./Google_Cloud_logo.svg.png";
import Miro from "./Miro_Logo.png";
import hubspot from "./hubspot_for_startup_logo.webp";
import Zendesk from "./Zendesk_logo.svg.png";
import Monday from "./Monday_Logo.png";
import Stripe from "./Stripe_Logo.png";
import Docsend from "./Docsend_Logo.webp";
import Techstars from "./Techstars_Logo.png";
import Wework from "./Wework_Logo.png";
import Notion from "./Notion_logo.svg.png";


export const ImagePartner = [
    Amazon,
    Google,
    Miro,
    hubspot,
    Zendesk,
    Monday,
    Stripe,
    Docsend,
    Techstars,
    Wework,
    Notion
]