import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { chunk } from "../util/chunk";
import { NetworksData2 } from "../storage/networks-data2";
import { useMemo } from "react";

export function SliderNetwork() {
  // const data = chunk(NetworksData2, 16);

  const data = useMemo(() => {
    if (window.innerWidth < 768) {
      return chunk(NetworksData2, 8);
    }

    if (window.innerWidth < 1024) {
      return chunk(NetworksData2, 12);
    }
    return chunk(NetworksData2, 16);
  }, []);

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '"></span>';
    },
  };

  return (
    <div className="mt-12 relative">
      <div className="build-us bg-white bg-opacity-70 rounded-2xl absolute top-0 left-0 right-0 bottom-14"></div>
      <Swiper
        className="networks "
        spaceBetween={50}
        slidesPerView={1}
        autoHeight={false}
        loop={true}
        autoplay={{ delay: 4500 }}
        pagination={pagination}
        modules={[Pagination, Autoplay]}
      >
        {data.map((i, index) => {
          return (
            <SwiperSlide key={`frame-swiper-${index}`} className="px-4 py-6 sm:py-16 sm:px-14">
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-10 text-center">
                {i.map((imgLink, imgIndex) => {
                  return (
                    <a
                      href={imgLink.link}
                      // className="max-h-20 max-w-[266px]"
                      className=" bg-white max-h-20 shadow-md rounded-lg p-2 sm:p-4 flex border border-gray-400 border-opacity-10"
                      target="_blank"
                      rel="noreferrer"
                      key={`img-networks-${imgIndex}`}
                    >
                      <img className="mx-auto max-h-20" src={imgLink.image} alt={`img-${imgLink.name}`} />
                    </a>
                  );
                })}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
