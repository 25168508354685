import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
// import { NetworksData } from "../storage/networks-data";
import { chunk } from "../util/chunk";
import { PartnerData } from "../storage/partners-data";
import { useMemo } from "react";

export function SliderPartner() {
  const data = useMemo(() => {
    if (window.innerWidth < 768) {
      return chunk(PartnerData, 2);
    }

    if (window.innerWidth < 1024) {
      return chunk(PartnerData, 4);
    }
    return chunk(PartnerData, 6);
  }, []);

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '"></span>';
    },
  };

  return (
    <div className="mt-12 relative">
      <div className="bgPartner absolute top-0 left-0 right-0 bottom-14"></div>
      <div className="mx-auto container">
        <Swiper
          className="networks "
          spaceBetween={50}
          slidesPerView={1}
          autoHeight={false}
          loop={true}
          // autoplay={{ delay: 4500 }}
          pagination={pagination}
          modules={[Pagination, Autoplay]}
        >
          {data.map((i, index) => {
            return (
              <SwiperSlide key={`frame-swiper-${index}`} className="">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-center text-center gap-[35px]">
                  {i.map((imgLink, imgIndex) => {
                    return (
                      <a
                        href={imgLink.link}
                        target="_blank"
                        rel="noreferrer"
                        className="bgBoxPartner"
                        key={`img-networks-${imgIndex}`}
                      >
                        <div className=" px-[29px] py-[23px] gap-y-9 flex flex-col">
                          <div className="h-[132px] flex items-center justify-center overflow-hidden">
                            <img src={imgLink.image} alt={imgLink.name} className="object-contain w-full h-full" />
                          </div>
                          <p className="text-left" dangerouslySetInnerHTML={{ __html: imgLink.des }}></p>
                        </div>
                      </a>
                    );
                  })}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
