import { ImageFeaturedGraduates } from '../assets/featured-graduates'

const name = ['Appota',
    'Monkey',
    'Hoayeuthuong',
    'Kyna',
    'Recruitery',
    'Telepro',
    'Beeketing',
    'Logivan',
    'Atadi',
    'FreelancerViet',
    'Wisami',
    'Unica'
]

const link = [
    'https://appota.com/',
    'https://monkey.edu.vn/san-pham/monkey-junior',
    'https://hoayeuthuong.com/',
    'https://kynaenglish.vn/',
    'https://recruitery.co/en-US',
    'https://telepro.me/',
    'https://beeketing.com/',
    'https://www.logivan.com/',
    'https://www.atadi.vn/ve-may-bay-gia-re',
    'https://freelancerviet.vn/',
    'https://wisami.com/',
    'https://unica.vn/'
]

export const FeaturedGraduatesData = name.map((i, index) => {
    return { name: i, link: link[index], image: ImageFeaturedGraduates[index] };
}).filter((i) => { return typeof i.image !== 'undefined' && i.image !== null });
