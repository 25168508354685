import Appota from './AppotaLogo.png';
import Monkey from './MonkeyLogo.png';
import Hoayeuthuong from './HoayeuthuongLogo.png';
import Kyna from './KynaLogo.svg';
import Recruitery from './recruiterylogo.png';
import Telepro from './TeleproLogo.png';
import Beeketing from './Beeketing.png';
import Logivan from './LogivanLogo.png';
import Atadi from './Atadilogo.png';
import FreelancerViet from './FreelancerVietlogo.svg';
import Wisami from './wisamilogo.jpeg';
import UnicLogo from './UnicLogo.png';

export const ImageFeaturedGraduates = [
    Appota,
    Monkey,
    Hoayeuthuong,
    Kyna,
    Recruitery,
    Telepro,
    Beeketing,
    Logivan,
    Atadi,
    FreelancerViet,
    Wisami,
    UnicLogo
]


