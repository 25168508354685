import { useCallback, useState } from "react";

export function FrequentlyQuestions() {
  const data = [
    {
      question: "1. What are deadlines for applications?",
      answer: `* Early Deadline: Apr 18 2023<br/>* Final Deadline: May 21 2023`,
    },
    {
      question: "2. When will the course begin?",
      answer: "Planned start date: June 1 2023",
    },
    {
      question: "3. How much time does the program require?",
      answer:
        "It requires a minimum of 20 hours of work per week, on average. (Weekly session plus additional networking hours: 2-3.5 hours Working group + weekly assignment: 10-20 hours)",
    },
    {
      question: "4. Can team or multiple co-founders also enroll?",
      answer:
        "We encourage founding teams to apply the program. We offer a discounted course fee per-founder if teams are enroll in the program. Teams of 2-3: 1.5x the course fee, and Teams of 4-5 for 2x the course fee.",
    },
    {
      question: "5. What happens if dropping out after the 3rd session?",
      answer:
        "After the Customer Development session, if you cannot finish the program for any reason, you can move your already paid course fee towards a future program. If the course fee for the new program is significantly higher than your original one, FI reserves the right to require you to pay the difference in order to enroll in the new program. However, you will not refund the difference if the course fee of the new program is lower",
    },
    {
      question: "6. Where are the sessions held?",
      answer: "Dreamplex is the venue sponsor for this batch.<br/>In Hanoi: Dreamplex, 174 Thai Ha Str., Trung Liet W., Dong Da Dist.<br/>In HCMC: Dreamplex, 62 Tran Quang Khai Str., Tan Dinh W., Dist. 1",
    },
    {
      question: "7. Can I submit more than one idea?",
      answer:
        "Up to you. We are more interested in the person, and less interested in the business idea. But Founders must select one idea to turn into a company prior to the Mentor Idea Review, 30 days into the program",
    },
    {
      question: "8. Can I apply if I have an established startup?",
      answer: `Yes. The program is appropriate for both aspiring founders, and founders that are running a business that is less than 3 years old and with less than half $1M in annual revenues. Founders that are more advanced in the program are put on the “Growth Track", which focuses more on generating traction and preparing for funding.`,
    },
    {
      question:
        "9. How many Founders will be in the program? And how many will graduate?  ",
      answer:
        "-> Between 20-40 Founders typically enroll. And 5-10 will be selected to graduate.",
    },
  ];

  const [active, setActive] = useState<number>(-1);
  const [more, setMore] = useState<boolean>(false);

  const handleSelect = useCallback(
    (index: number) => {
      if (active === index) {
        setActive(-1);
      } else {
        setActive(index);
      }
    },
    [active]
  );

  return (
    <div id="FAQ" className="pt-16 pb-12">
      <h2 className="font-bold text-[40px] leading-[1.15] text-center text-[#676767]">
        Frequently Asked Questions
      </h2>

      <div className="flex flex-col px-[5%] mt-12">
        {data.map((i, index) => {
          return (
            <div
              key={`quesiton-Frequently-${index}`}
              className={`transition-all duration-300 ease-in-out overflow-hidden ${
                index > 2 && !more
                  ? "max-h-0 pb-0 mt-0 border-b-0"
                  : "max-h-[999px] pb-4 mt-10  border-b border-b-[#DCDCDC]"
              }`}
            >
              <div
                className="flex justify-between cursor-pointer"
                onClick={() => {
                  handleSelect(index);
                }}
              >
                <p className="font-bold text-base leading-5 text-[#2F2F2F]">
                  {i.question}
                </p>
                <div
                  className={`transition-all duration-200 ease-in ${
                    active === index ? "rotate-180" : "rotate-0"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={19}
                    viewBox="0 0 32 19"
                    fill="none"
                  >
                    <path
                      d="M2 2L16 16L30 2"
                      stroke="#C4C4C4"
                      strokeWidth={4}
                    />
                  </svg>
                </div>
              </div>
              <p
                className={`mt-4 transition-all overflow-hidden ease-in duration-300 font-sans text-base text-[#575757] ${
                  active === index
                    ? "max-h-[99999px] opacity-100"
                    : "max-h-0 opacity-0"
                }`}
                dangerouslySetInnerHTML={{ __html: i.answer }}
              ></p>
            </div>
          );
        })}
      </div>

      <div className="mx-auto mt-12 items-center flex justify-center">
        {data.length > 2 && (
          <div
            className=" inline-flex gap-x-1 items-center justify-center cursor-pointer"
            onClick={() => {
              setMore(!more);
            }}
          >
            <p className="text-[#989898] font-bold text-base leading-5">
              {more ? "See less" : "See more"}
            </p>
            <div
              className={`transition-all duration-200 ease-in ${
                more ? "rotate-180" : "rotate-0"
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={32}
                height={19}
                viewBox="0 0 32 19"
                fill="none"
              >
                <path d="M2 2L16 16L30 2" stroke="#C4C4C4" strokeWidth={4} />
              </svg>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
