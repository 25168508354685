import { SliderFeaturedGraduates } from "./slider-featured-gradutes";

export function FeaturedGraduates() {
  return (
    <div id="Alumni" className="text-center py-16 ">
      <h3 className="font-bold text-[40px] leading-[1.15] text-center text-[#676767]">
        Featured <span className="text-Funding">Graduates</span>
      </h3>
      <SliderFeaturedGraduates />
    </div>
  );
}
