import { useCallback } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import { HeaderLayout } from "./header";
import { FooterLayout } from "./footer";
import { ScrollRestoration } from "react-router-dom";
import type { Location, useMatches } from "react-router-dom";

export function Layout() {

  const getKey = useCallback(
    (location: Location, matches: ReturnType<typeof useMatches>) => {
      let match = matches.find((m) => (m.handle as any)?.scrollMode);
      if ((match?.handle as any)?.scrollMode === "pathname") {
        return location.pathname;
      }

      return location.key;
    },
    []
  );

  return (
    <main className="min-h-screen flex flex-col overflow-y-auto overflow-x-hidden">
      <HeaderLayout />
      <Outlet />
      <FooterLayout />
      <ScrollRestoration getKey={getKey} />
    </main>
  );
}
