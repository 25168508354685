import insignia_logo from "./insignia_logo.png";
import ALtara from "./ALtara.png";
import KK_logo from "./KK_logo.png";
import IDG_logo from "./IDG_logo.png";
import VinaCapital_Venture from "./VinaCapital_Venture.png";
import Touchstone from "./Touchstone.png";
import Sequoia_Capital_logo from "./Sequoia_Capital_logo.svg.png";
import Lighspeed from "./Lighspeed.png";
import do_ventures from "./do_ventures.png";
import plug_and_play_logo from "./plug_and_play_logo.png";
import Monks_Hill_Ventures from "./Monks_Hill_Ventures.png";
import AVV_Logo_Color_Black_HD from "./AVV_Logo_Color_Black_HD.png";
import Openspace from "./Openspace.png";
import GGV_capital_logo from "./GGV_capital_logo.png";
import VI_Group from "./VI_Group.jpeg";
import Patamar_Primary_Logo from "./Patamar_Primary_Logo.jpeg";
import Gobi_Partners_Logo_Black from "./Gobi_Partners_Logo_Black.png";
import gg from "./gg.png";
import saison_capital from "./saison_capital.png";
import Access_Ventures from "./Access_Ventures.png";
import Phoenix_holdings from "./Phoenix_holdings.jpeg";
import SoftBank_Vision_Fund from "./SoftBank_Vision_Fund.svg.png";
import Velocity from "./Velocity.png";
import Wavemaker from "./Wavemaker.png";
import WTP_Capital from "./WTP_Capital.png";
import Viisa from "./Viisa.png";
import Venturra from "./Venturra.png";
import CyberAgent_Capital from "./CyberAgent_Capital.jpeg";
import Headline from "./Headline.png";
import Antler from "./Antler.svg";
import i500 from "./500.webp";
import Genesia_Ventures from "./Genesia_Ventures.png";
import Betatron from "./Betatron.png";
import Stripe_Logo from "./Stripe_Logo.png";
import MDI_Logo from "./MDI_Logo.png";
import Bace_logo from "./Bace_logo.png";
import Endevour from "./Endevour.png";
import Line from "./Line.png";
import BPI_Logo from "./BPI_Logo.png";
import qualgro from "./qualgro.png";
import presidio from "./presidio.png";
import Ondine from "./Ondine.avif";
import mistletoe_logo_clear from "./mistletoe_logo_clear.png";
import AppWorks_Logo from "./AppWorks_Logo.png";
import BCapitalGroup from "./BCapitalGroup.png";
import FEBE from "./FEBE.png";
import Think_Zone_Logo from "./Think_Zone_Logo.png";
import LogoAucfan from "./LogoAucfan.png";
import logo_leo from "./logo_leo.png";
import addventure from "./addventure.png";
import Intervest from "./Intervest.png";
import seax_ventures_old_logo from "./seax_ventures_old_logo.png";
import Creative from "./Creative.png";
import scb_10x from "./scb_10x.png";
import Koru from "./Koru.png";
import SV from "./SV.jpeg";
import Quiming from "./Quiming.png";
import SIG from "./SIG.png";
import Spiral from "./Spiral.png";
import WhiteStar from "./WhiteStar.png";
import Orion from "./Orion.png";
import sparklabs_taipei from "./sparklabs_taipei.png";
import Acecapital from "./Acecapital.png";
import China_accelerator from "./China_accelerator.png";
import bon_angel from "./bon_angel.png";
import xpdite from "./xpdite.webp";
import Mynavi_Logo from "./Mynavi_Logo.png";
import Big_Idea_Ventures from "./Big_Idea_Ventures.png";
import beenext_logo_bold from "./beenext_logo_bold.gif";
import eight_roads from "./eight_roads.png";
import Beacon_Logo from "./Beacon_Logo.png";
import colopl_logo from "./colopl_logo.png";
import Atlasventure from "./Atlasventure.png";
import Next100 from "./Next100.png";
import vectr_logo_black from "./vectr_logo_black.png";
import amasia from "./amasia.png";
import Quest_Ventures_logo from "./Quest_Ventures_logo.png";
import esp from "./esp.webp";
import Investible from "./Investible.png";
import tauruslogo from "./tauruslogo.jpeg.crdownload.jpg";
import Hive_Ventures from "./Hive_Ventures.png";
import ByteDance_logo_English from "./ByteDance_logo_English.svg.png";
// import GoVenture from "./GoVenture.avif";
import Siri_Ventures from "./Siri_Ventures.png";
import Zino_Ventures from "./Zino_Ventures.avif";
import Legend_Capital from "./Legend_Capital.png";
import TRIVE_Logo__Grey__28Oct from "./TRIVE_Logo__Grey__28Oct.png";
import SOSV from "./SOSV.png";
import _cento_logo_final from "./cento_logo_final.png";
import cropped_MOX_in_blue from "./cropped_MOX_in_blue.png";
import ATM_Capital from "./ATM_Capital.png";
import Momentum_Ventures from "./Momentum_Ventures.png";
import WI_Harper from "./WI_Harper.png";
import Elev8 from "./Elev8.png";
import TNBAura from "./TNBAura.png";
import Quona_Capital from "./Quona_Capital.png";
import Amand_Ventures from "./Amand_Ventures.png";
import Sema_Translink_Investment from "./Sema_Translink_Investment.avif";
import Kasikorn_Vision from "./Kasikorn_Vision.png";
import Jungle_logo from "./Jungle_logo.png";


export const ImageNetworks2 = [
    insignia_logo,
    ALtara,
    KK_logo,
    IDG_logo,
    VinaCapital_Venture,
    Touchstone,
    Sequoia_Capital_logo,
    Lighspeed,
    do_ventures,
    plug_and_play_logo,
    Monks_Hill_Ventures,
    AVV_Logo_Color_Black_HD,
    Openspace,
    GGV_capital_logo,
    VI_Group,
    Patamar_Primary_Logo,
    Gobi_Partners_Logo_Black,
    gg,
    saison_capital,
    Access_Ventures,
    Phoenix_holdings,
    SoftBank_Vision_Fund,
    Velocity,
    Wavemaker,
    WTP_Capital,
    Viisa,
    Venturra,
    CyberAgent_Capital,
    Headline,
    Antler,
    i500,
    Genesia_Ventures,
    Betatron,
    Stripe_Logo,
    MDI_Logo,
    Bace_logo,
    Endevour,
    Line,
    BPI_Logo,
    qualgro,
    presidio,
    Ondine,
    mistletoe_logo_clear,
    AppWorks_Logo,
    BCapitalGroup,
    FEBE,
    Think_Zone_Logo,
    LogoAucfan,
    logo_leo,
    addventure,
    Intervest,
    seax_ventures_old_logo,
    Creative,
    scb_10x,
    Koru,
    SV,
    Quiming,
    SIG,
    Spiral,
    WhiteStar,
    Orion,
    sparklabs_taipei,
    Acecapital,
    China_accelerator,
    bon_angel,
    xpdite,
    Mynavi_Logo,
    Big_Idea_Ventures,
    beenext_logo_bold,
    eight_roads,
    Beacon_Logo,
    colopl_logo,
    Atlasventure,
    Next100,
    vectr_logo_black,
    amasia,
    Quest_Ventures_logo,
    esp,
    Investible,
    tauruslogo,
    Hive_Ventures,
    ByteDance_logo_English,
    Siri_Ventures,
    Zino_Ventures,
    Legend_Capital,
    TRIVE_Logo__Grey__28Oct,
    SOSV,
    _cento_logo_final,
    cropped_MOX_in_blue,
    ATM_Capital,
    Momentum_Ventures,
    WI_Harper,
    Elev8,
    TNBAura,
    Quona_Capital,
    Amand_Ventures,
    Sema_Translink_Investment,
    Kasikorn_Vision,
    Jungle_logo,
]