import { ImageAssets } from "../assets";
import { ImageSince } from "../assets/since";
import { ImageBuildUs } from "../assets/build_us";
import { BtnApplyBatch9 } from "../components/btn-apply-battch-9";
import { FrequentlyQuestions } from "../components/frequently-questions";
import "swiper/css";
import { Networks } from "../components/networks";
import { Mentors } from "../components/mentors";
import { FeaturedGraduates } from "../components/featured-gradutes";
import { AboutTFI } from "../components/about-tfi";
import { PartnerOffers } from "../components/partmer_offers";

export function HomePage() {
  const since = [
    {
      label: "Batches",
      img: ImageSince.since1,
      count: "8",
    },
    {
      label: "Graduate Startups",
      img: ImageSince.since2,
      count: "90+",
    },
    {
      label: "Fund Raised",
      img: ImageSince.since3,
      count: "$100M+",
    },
    {
      label: "Estimated Valuation",
      img: ImageSince.since4,
      count: "$800M+",
    },
  ];

  const buildUs = [
    {
      img: ImageBuildUs.buildUs1,
      label: "Lifetime Direction:",
      content:
        "Structured programs designed to provide targeted support across your startup journey, from Idea to Exit",
    },
    {
      img: ImageBuildUs.buildUs2,
      label: "Sponsorship & Discount Services:",
      content:
        "Over $2M in discounts and freebies from AWS, Google, Zendesk, Stripe and many more supports startup ecosystem growth",
    },
    {
      img: ImageBuildUs.buildUs3,
      label: "Access to Network:",
      content: "both TFI Mafia and Global Network of FI",
    },
  ];

  return (
    <>
      <section className="container mx-auto">
        {/*SECTION: Zero to Funding in 14 weeks*/}
        <div className="gap-x-7 flex lg:flex-row flex-col">
          <div className="w-full lg:w-1/2 pt-[115px]">
            <p className="text-[#676767] font-black text-[26px] leading-[1.31]">
              TFI Batch 9 | 2023
            </p>
            <h2 className="text-[#676767] font-bold text-5xl sm:text-7xl leading-tight mt-5">
              Zero to <span className="text-Funding">Funding</span>
              <br />
              in 14 weeks
            </h2>
            <p className="text-[#676767] font-black text-[26px] leading-[1.31] mt-5">
              Launching in Hanoi and HCMC
            </p>
            <p className="text-[#676767] font-medium text-base mt-16 max-w-lg">
              TFI accelerator provides early-stage and aspiring entrepreneurs
              with the structure, training, mentor support, and global network
              needed to start an enduring company.
            </p>
            <div className="mt-12">
              <BtnApplyBatch9 />
            </div>
          </div>
          <div className="w-full lg:w-1/2 mt-[66px] relative">
            <img
              src={ImageAssets.cover}
              alt="Zero to Funding in 14 weeks"
              className=""
            />
            {/* <img
            src={ImageAssets.cover}
            alt="Zero to Funding in 14 weeks"
            className=" absolute top-0 right-0"
            style={{ transform: "translateX(70px)" }}
          /> */}
          </div>
        </div>

        {/*SECTION: Since 2011*/}
        <div className="text-center mt-16">
          <div
            className="relative inline-flex since-2011 items-center justify-center
        font-black text-[26px] leading-[1.31] text-[#676767]"
          >
            Since 2011
          </div>
          <p className="font-bold text-[18px] sm:text-[32px] md:text-[40px] leading-[1.15] text-center text-[#676767]">
            TFI has indeed contributed to the establishment of a complete
            startup ecosystem for Vietnam startups to grow and go global.{" "}
          </p>

          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-x-[35px]">
            {since.map((i, index) => {
              return (
                <div
                  key={`since-${index}`}
                  className="p-4 sm:p-[45px] flex flex-col items-center gap-y-3"
                >
                  <img
                    src={i.img}
                    alt={i.label}
                    className="object-contain max-w-[142px]"
                  />
                  <p className="text-[#676767] font-bold text-[28px] sm:text-[40px] leading-none text-center">
                    {i.count}
                  </p>
                  <p className="text-[#676767] font-black text-[18px] sm:text-[26px] leading-[1.31] text-center">
                    {i.label}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        {/*SECTION: Build a fundable business with us*/}
        <div className="text-center mt-16 gap-y-10 flex flex-col items-center pb-16">
          <h3 className="font-bold text-[32px] sm:text-[40px] leading-[1.15] text-center text-[#676767]">
            Build a <span className="text-Funding">fundable business</span> with
            us
          </h3>

          <p className="font-medium text-base text text-center text-[#676767] max-w-[67%]">
            Being a local chapter of Founder Institute (FI, Silicon Valley, US),
            TFI has combined both local and global benefits and support that far
            exceeds any comparable program
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 mx-auto py-11 gap-x-[35px] build-us px-[8.3%] bg-white bg-opacity-70 rounded-2xl">
            {buildUs.map((i, index) => {
              return (
                <div
                  key={`build_us-${index}`}
                  className="px-4 md:px-[27px] py-6 md:py-[33px] flex flex-col items-center"
                >
                  <img src={i.img} alt={i.label} />
                  <p className="mt-6 text-[#676767] font-bold text-xl leading-[1.3] text-center">
                    {i.label}
                  </p>
                  <p className="mt-2  text-[#676767] font-medium text-base text-center">
                    {i.content}
                  </p>
                </div>
              );
            })}
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-4 w-full px-[5%] gap-y-4 lg:gap-y-0">
            <div className="sm:basis-1/2 lg:basis-1/4 flex items-center justify-center">
              <p className="px-4 text-[#676767] font-medium text-base text-center w-full border-r-[2px] border-black">
                14 Sessions
              </p>
            </div>
            <div className="sm:basis-1/2 lg:basis-1/4 flex items-center justify-center">
              <p className="px-4 text-[#676767] font-medium text-base text-center w-full border-r-[2px] border-transparent lg:border-black">
                14 Weeks
              </p>
            </div>
            <div className="sm:basis-1/2 lg:basis-1/4 flex items-center justify-center">
              <p className="px-4 text-[#676767] font-medium text-base text-center w-full border-r-[2px] border-black">
                50+ Mentors
              </p>
            </div>
            <div className="sm:basis-1/2 lg:basis-1/4 flex items-center justify-center">
              <p className="px-4 text-[#676767] font-medium text-base text-center">
                Founder-Investor Showcase with 100+ Investors
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-center gap-y-4 lg:gap-y-0 gap-x-0 lg:gap-x-12 mx-auto">
            <a
              href="https://fi.co/join"
              className="bg-btn px-6 py-4 font-bold text-white text-[28px] leading-[1] rounded-full w-full lg:basis-1/2 lg:min-w-[303px] text-center"
              target="_blank"
              rel="noreferrer"
            >
              Apply Now
            </a>
            <div className="bg-btn p-[2px] font-bold text-[#434343] text-[28px] leading-[1] rounded-full lg:basis-1/2 text-center">
              <a
                href="mailto:hanguyen.tfi@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <div className="px-[22px] py-[14px] w-full bg-white rounded-full ">
                  Request prospectus
                </div>
              </a>
            </div>
          </div>
        </div>

        {/*SECTION: Frequently Asked Questions*/}
        <FrequentlyQuestions />

        {/*SECTION: Featured Graduates */}
        <FeaturedGraduates />

        {/*Section: Mentors */}
        <Mentors />
      </section>
      {/* Section:PartnerOffers */}
      <PartnerOffers/>

      <section className="container mx-auto">
        {/*SECTION: Investors Network */}
        <Networks />

        {/*SECTION: AboutTFI*/}
        <AboutTFI />

        <div className="py-16 flex gap-y-[37px] flex-col items-center">
          <h3 className="font-bold text-[40px] leading-[1.15] text-center text-[#676767]">
            Take <span className="text-Funding">your idea</span> to the next
            stage with TFI Accelerator 2023
          </h3>
          <p className="text-center text-[#8B8B8B] text-base font-medium">
            Receive constant feedback and advice from experts | Expand your
            network and connect to an unparalleled global network
          </p>
          <div className="flex items-center justify-center">
            <a
              href="https://fi.co/join"
              className="bg-btn px-6 py-4 font-bold text-white text-[28px] leading-[1] rounded-full min-w-[303px] text-center"
              target="_blank"
              rel="noreferrer"
            >
              Apply Now
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
