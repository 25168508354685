import { ImageTeams } from "../assets/team";

export const TeamData = [
    {
        name: 'Tuan Pham',
        image: ImageTeams.TuanPham,
        image2: ImageTeams.TuanPham2,
        office: 'Chairman & Co-Founder',
        des: 'Dr. Tuan Pham is the Founder of Topica, a leading Edtech group in South East Asia with over 2,000 employees in Indonesia, the Philippines, Thailand and Vietnam which has raised 65+ million USD from some venture capital firms to date. Tuan co-founded TFI in 2011 to help build the next generation of startups, and its alumni accounted for 30% of all early-stage investments in Vietnam in 2016. Tuan used to serve as Vice Dean and Dean at Hanoi University of Technology and Hanoi Open University, and Senior Visiting Scholar at the University of California San Diego. He worked at McKinsey&Company (Budapest), Extramedia Ventures (Boston, Singapore) and Integra Software (Budapest). Tuan was honored as an Endeavor Entrepreneur in 2019, and a Young Global Leader by World Economic Forum in 2012. He had been serving as a judge of Global Teacher Prize since 2016, and a member of the Vietnam National Education and HR Development Council chaired by the Prime Minister since 2018. Tuan now contributes his time to advising and mentoring some tech startups & founders.'
    },
    {
        name: 'Cong Tran',
        image: ImageTeams.CongTran,
        image2: ImageTeams.CongTran2,
        office: 'Partner & Co-Founder',
        des: 'Cong Tran is a Partner/ Co-Founder of Tech Founder Institute (TFI) since 2011 which has accelerated 90+ tech startups in Vietnam. Cong Tran has been an active startup and venture ecosystem builder with his portfolios in fintech, online payment, edtech since 2006. Besides that, Cong Tran has managed to close $150m+ in funding from international venture capital/ private equity/ offshore debt funds and strategic corporate investors with 20+ investment transactions so far. He was also selected to Forbes Vietnam 30 Under 30 in 2018.',
    },
    {
        name: 'Quang Mai',
        image: ImageTeams.QuangMai,
        image2: ImageTeams.QuangMai2,
        office: 'Co-Director & Co-Founder',
        des: `Quang Mai is a serial entrepreneur. He has 20+ years of experience in IT space and has founded some tech startups. He's also a Vice Chairman in charge of startup activity of Vietnam Software & IT services. He is very active in Vietnam's startup community, volunteering his time to advise startups and being a judge in some startup competitions. He used to join developing eGovernment services in Germany and Vietnam with net-Com AG (net-com.de) (funded by the German government) as the Director of Southeast Asia from 2006 to 2011. He recently joins Vietnam Blockchain Association as one of the Members of the Executive Committee.`,
    },
    {
        name: 'Bobby Liu',
        image: ImageTeams.BobbyLiu,
        image2: ImageTeams.BobbyLiu2,
        office: 'Program Advisor & Co-Founder',
        des: `Having lived and worked in 6 different countries, Bobby Liu is a keen learner and appreciates the differences in cultural backgrounds of people and what makes them tick. Ideas abound, he started Hub.IT, a co-working space/incubator that provided young entrepreneurs a conducive environment to innovate and share collective experiences, and in the process contributed to building a great entrepreneurial ecosystem in Hanoi and Vietnam. In 2015, Hub.IT was acquired by Topica, where he's the Senior Director at Topica Edtech Group and Co-Director of the TFI. He is also very active in the startup community, volunteering his time to mentor startups and helping them achieve their goals. He recently joins Touchstone Partners as a Director Of Entrepreneur-In-Residence. And he still dedicatedly contributes to TFI as a Program Advisor.`
    },
    {
        name: 'Ha Nguyen',
        image: ImageTeams.HaNguyen,
        image2: ImageTeams.HaNguyen2,
        office: 'Program Manager',
        des: `Ha Nguyen has joined TFI as a Program Manager since 2019. Prior to joining TFI, she started her career as a member in fundraising team or executive assistant to C-Levels in some tech startups. She has diverse setup & operations experience with local startups in Vietnam. She co-founds and now runs HotQA, a startup that provides community-driven quiz game platform empowering users by rewarding through engagement and enjoyment. She's also an event manager who operates Vietnam Blockchain Summit, an international blockchain event, annually.`
    }
]