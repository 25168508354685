import { ImageAssets } from "../assets";
import { ImageSocial } from "../assets/footer";

export function FooterLayout() {
  return (
    <div className="bg-[#454545]">
      <footer className="container mx-auto">
        <div className="flex gap-x-24 items-start sm:justify-center px-4 py-24 flex-wrap gap-y-8">
          <img
            src={ImageAssets.logoWhite}
            alt="TFI TECH Founder Institute"
            className="-mt-6"
          />
          <div className="flex flex-col gap-y-4">
            <p className="text-white font-bold text-xl leading-[1.3]">
              Follow us on
            </p>
            <div className="flex gap-x-[30px] items-center">
              {/* <img src={ImageSocial.Linkedin} alt="Linkedin" /> */}
              <a
                href="https://www.facebook.com/tfiaccelerator"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ImageSocial.facebook} alt="Facebook" />
              </a>
              <a
                href="https://www.youtube.com/@techfounderinstitute"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ImageSocial.youtube} alt="Youtube" />
              </a>
            </div>
            <p className="text-white font-bold text-xl leading-[1.3]">
              Contact: hanguyen.tfi@gmail.com
            </p>
          </div>

          <ul className="flex flex-col gap-y-4">
            <li className="text-white font-bold text-xl leading-[1.3]">
              ABOUT
            </li>
            <li className="text-white font-bold text-xl leading-[1.3]">
              About TFI
            </li>
            <li className="text-white font-bold text-xl leading-[1.3]">
              <a
                href="https://drive.google.com/drive/folders/1sKaCp4e0jO-6ic2LIAyiJu-X87W2ynPW?usp=share_link"
                target="_blank"
                rel="noreferrer"
                className="text-white font-bold text-xl leading-[1.3] hover:underline"
              >
                Media Kit
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}
