import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { MentorsData } from "../storage/mentors-data";
import { chunk } from "../util/chunk";
import { useMemo } from "react";

export function Mentors() {
  const data = useMemo(() => {
    if (window.innerWidth < 768) {
      return chunk(MentorsData, 6);
    }
    if (window.innerWidth < 1024) {
      return chunk(MentorsData, 8);
    }
    return chunk(MentorsData, 12);
  }, []);

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '"></span>';
    },
  };

  return (
    <div className="text-center py-16">
      <h3 className="font-bold text-[40px] leading-[1.15] text-center text-[#676767]">
        Some of TFI <span className="text-Funding">mentors</span>
      </h3>
      <div className="mt-16 relative">
        <Swiper
          className="mentors"
          spaceBetween={50}
          slidesPerView={1}
          autoHeight={false}
          loop={true}
          autoplay={{ delay: 6500 }}
          pagination={pagination}
          modules={[Pagination, Autoplay]}
        >
          {data.map((i, index) => {
            return (
              <SwiperSlide key={`frame-mentor-${index}`}>
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-[35px] items-start justify-center text-center">
                  {i.map((mentor, mentorIndex) => {
                    return (
                      <div key={`mentor-${mentorIndex}`}>
                        <img
                          className="mx-auto w-[147px] h-[147px] rounded-full overflow-hidden object-cover"
                          src={mentor.img}
                          alt={`${mentor.name}`}
                        />
                        <h3 className="mt-2 text-Funding font-bold text-lg sm:text-[28px] leading-[1.21] text-center">
                          {mentor.name}
                        </h3>
                        <p className="text-[#676767] mt-2 font-medium text-sm sm:text-[18px] leading-[1.33] text-center">
                          {mentor.text1}
                        </p>
                        <p className="text-[#676767] font-medium text-sm sm:text-[18px] leading-[1.33] text-center">
                          {mentor.text2}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
