import React from "react";
import ReactDOM from "react-dom";
import { TeamData } from "../storage/team-data";
import BGTeam from "../assets/team/BG_team.png";
import Xcircle from "../assets/team/x-circle.png";
import { useState } from "react";

const PopupTeam = (props: {
  show: boolean;
  onClose: () => void;
  data: any;
}) => {
  const { data } = props;

  if (!props.show) {
    return null;
  }

  return ReactDOM.createPortal(
    <React.Fragment>
      <div
        className="fixed bg-black bg-opacity-50 top-0 left-0 w-full h-full"
        style={{ zIndex: 1055 }}
      ></div>
      <div
        className="fixed top-0 left-0 w-full h-full z-50 overflow-y-auto overflow-x-hidden block"
        style={{ zIndex: 1055 }}
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (typeof props.onClose === "function") props.onClose();
        }}
      >
        <div className={"w-auto relative flex items-center modal-content"}>
          <div
            className="relative flex flex-col md:flex-row w-full bg-white outline-none max-w-[1200px] mx-auto pModal modal-wrapper rounded-[20px] justify-between items-center gap-x-[35px]"
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            <button
              className="absolute z-10 top-[23px] right-[27px]"
              onClick={() => {
                if (typeof props.onClose === "function") props.onClose();
              }}
            >
              <img src={Xcircle} alt="Close" />
            </button>
            <div className="rounded-[20px] bg-[#E9CC66] basis-[385px]">
              <img
                src={data.image2}
                alt={data.name}
                className="rounded-[20px]"
              />
            </div>
            <div className="flex-1 mt-6 md:mt-0">
              <h2 className="text-Funding font-bold text-[72px] leading-[1]">
                {data.name}
              </h2>
              <p className="text-[#676767] font-bold text-[40px] leading-[1.15] mt-5">
                {data.office}
              </p>
              <p className="text-[#676767] font-medium text-base mt-5">
                {data.des}
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>,
    document.body
  );
};

export function TeamPage() {
  const [show, setShow] = useState<number>(-1);

  return (
    <div className="container mx-auto flex-auto py-28">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-[34px] gap-y-[55px]">
        <div className="col-span-1 pt-[83px] relative">
          <img
            src={BGTeam}
            alt="BGTeam"
            className="absolute -top-[26px] -left-[93px]"
          />
          <h1 className="text-7xl leading-none text-[#676767] font-bold relative z-10">
            Our <span className="text-Funding">Team</span>
          </h1>
          <p className="font-medium text-base text-[#676767] relative z-10 mt-6">
            TFI is operated by dedicated entrepreneurs & investment
            professionals, who are active members/mentors of Vietnam startup
            community.
          </p>
        </div>
        {TeamData.map((i, index) => {
          return (
            <div
              className="col-span-1 cursor-pointer"
              key={i.name}
              onClick={() => {
                setShow(index);
              }}
            >
              <div className="aspect-square bg-[#E9CC66] rounded-t-2xl overflow-hidden text-center flex items-end relative">
                <img src={i.image} alt={i.name} className="mx-auto" />
                <div className=" bg-img-team absolute inset-0" />
              </div>
              <div className="px-6 py-4 -mt-[38px] relative z-20">
                <h2 className="text-Funding font-bold text-[40px] leading-[1.15]">
                  {i.name}
                </h2>
                <p className="text-[#676767] font-medium text-lg leading-[1.33] mt-[7px]">
                  {i.office}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <PopupTeam
        show={show !== -1}
        data={TeamData[show]}
        onClose={() => {
          setShow(-1);
        }}
      />
    </div>
  );
}
