import TuanPham from './TuanPham.png'
import CongTran from './CongTran.png'
import QuangMai from './QuangMai.png'
import BobbyLiu from './BobbyLiu.png'
import HaNguyen from './HaNguyen.png'

import TuanPham2 from './TuanPham2.png'
import CongTran2 from './CongTran2.png'
import QuangMai2 from './QuangMai2.png'
import BobbyLiu2 from './BobbyLiu2.png'
import HaNguyen2 from './HaNguyen2.png'

export const ImageTeams = {
    TuanPham, CongTran, QuangMai, BobbyLiu, HaNguyen,
    TuanPham2, CongTran2, QuangMai2, BobbyLiu2, HaNguyen2

}