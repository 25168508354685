import TanYinglan from "../assets/mentors/TanYinglan.jpg";
import Vyle from "../assets/mentors/Vyle.jpg";
import DucTran from "../assets/mentors/DucTran.jpg";
import KoichiSaito from "../assets/mentors/KoichiSaito.jpg";
import ShuyinTang from "../assets/mentors/ShuyinTang.jpg";
import RichardHan from "../assets/mentors/RichardHan.jpg";
import EddieThai from "../assets/mentors/EddieThai.jpg";
import CharlesRim from "../assets/mentors/CharlesRim.jpg";
import TrungAnhNguyen from "../assets/mentors/TrungAnhNguyen.jpg";
import PhoPham from "../assets/mentors/PhoPham.jpg";
import PacharaLawjindakul from "../assets/mentors/PacharaLawjindakul.jpg";
import dzungnguyen from "../assets/mentors/dzungnguyen.png";
//slider2
import KennethTan from "../assets/mentors/KennethTan.jpg";
import MinhLe from "../assets/mentors/MinhLe.jpg";
import SonTran from "../assets/mentors/SonTran.png";
import AaeronEverhart from "../assets/mentors/AaeronEverhart.jpg";
import YenDo from "../assets/mentors/YenDo.jpg";
import QuentinFrecon from "../assets/mentors/QuentinFrecon.jpg";
import ChristianSchaefer from "../assets/mentors/ChristianSchaefer.jpg";
import DoHung from "../assets/mentors/DoHung.jpg";
import DoTuanAnh from "../assets/mentors/DoTuanAnh.jpg";
import JonahLevey from "../assets/mentors/JonahLevey.jpg";
import DavidSalt from "../assets/mentors/DavidSalt.jpg";
import JonMyers from "../assets/mentors/JonMyers.jpg";
//slider3
import JimmyHTran from "../assets/mentors/JimmyHTran.jpg";
import VietNguen from "../assets/mentors/VietNguen.jpg";
import DungTran from "../assets/mentors/DungTran.jpg";
import NiraanDeSilva from "../assets/mentors/NiraanDeSilva.jpg";
import NgonPham from "../assets/mentors/NgonPham.jpg";
import TonyDo from "../assets/mentors/TonyDo.jpg";
import ToLinhTruong from "../assets/mentors/ToLinhTruong.jpg";
import HuyShayneNghiem from "../assets/mentors/HuyShayneNghiem.jpg";
import HoangEricNguyen from "../assets/mentors/HoangEricNguyen.jpg";
import QuanTruong from "../assets/mentors/QuanTruong.jpg";
import MyTran from "../assets/mentors/MyTran.jpg";
import PhuongTran from "../assets/mentors/PhuongTran.jpg";
//slider4
import NamLe from "../assets/mentors/NamLe.jpg";
import NgocDiepNguyen from "../assets/mentors/NgocDiepNguyen.jpg";
import TuanNguyen from "../assets/mentors/TuanNguyen.jpg";
import DzungHoang from "../assets/mentors/DzungHoang.jpg";
import RossMacleod from "../assets/mentors/RossMacleod.jpg";
import DungPham from "../assets/mentors/DungPham.jpg";
import PhamMinhToan from "../assets/mentors/PhamMinhToan.jpg";
import ThangTran from "../assets/mentors/ThangTran.jpg";
import ThanhVu from "../assets/mentors/ThanhVu.jpg";
import TruongNguyen from "../assets/mentors/TruongNguyen.jpg";
import HaLam from "../assets/mentors/HaLam.jpg";
import DucAnhTrinh from "../assets/mentors/DucAnhTrinh.jpg";
//slider5
import HajimeHotta from "../assets/mentors/HajimeHotta.jpg";
import CharlesLee from "../assets/mentors/CharlesLee.jpg";
import RyanGalloway from "../assets/mentors/RyanGalloway.jpg";
import BobbyLiu from "../assets/mentors/BobbyLiu.jpg";

const slider1 = [
    {
        img: TanYinglan,
        name: 'Tan Yinglan',
        text1: 'Founding Managing Partner',
        text2: 'Insignia Ventures Partners',
    },
    {
        img: Vyle,
        name: 'Vy Le',
        text1: 'Co-founder and General Partner',
        text2: 'DO Ventures',
    },
    {
        img: DucTran,
        name: 'Duc Tran',
        text1: 'General Partner',
        text2: 'IDG Ventures Vietnam',
    },
    {
        img: KoichiSaito,
        name: 'Koichi Saito',
        text1: 'Founder & General Partner',
        text2: 'KK Fund',
    },
    {
        img: ShuyinTang,
        name: 'Shuyin Tang',
        text1: 'Partner',
        text2: 'Patamar Capital',
    },
    {
        img: RichardHan,
        name: 'Richard Han',
        text1: 'Director',
        text2: 'VinaCapital Ventures',
    },
    {
        img: EddieThai,
        name: 'Eddie Thai',
        text1: 'General Partner',
        text2: 'Ascend Vietnam Ventures (AVV)',
    },
    {
        img: CharlesRim,
        name: 'Charles Rim',
        text1: 'General & Founding Partner',
        text2: 'Access Ventures LLC',
    },
    {
        img: TrungAnhNguyen,
        name: 'Trung Anh Nguyen',
        text1: 'CEO, Phoenix Holdings/ Board Member of Timo/ Board Member',
        text2: 'Viet Capital Securities',
    },
    {
        img: PhoPham,
        name: 'Pho Pham',
        text1: 'CEO',
        text2: 'Viet Capital Asset Management (VCAM)',
    },
    {
        img: PacharaLawjindakul,
        name: 'Pachara (Pinn) Lawjindakul',
        text1: 'Partner',
        text2: 'Lightspeed Venture Partners',
    },
    {
        img: dzungnguyen,
        name: 'Dzung Nguyen',
        text1: 'Founder & General Partner',
        text2: 'DO Ventures',
    }
]

const slider2 = [
    {
        img: KennethTan,
        name: 'Kenneth Tan',
        text1: 'Vice President',
        text2: 'Gobi Partner',
    },
    {
        img: MinhLe,
        name: 'Minh Le',
        text1: 'Co-founder',
        text2: 'Chairman & CEO, VNG',
    },
    {
        img: SonTran,
        name: 'Son Tran',
        text1: 'Founder & CEO',
        text2: 'TIKI Corporation',
    },
    {
        img: AaeronEverhart,
        name: 'Aaeron Everhart',
        text1: 'Country Lead',
        text2: 'Draper Startup House',
    },
    {
        img: YenDo,
        name: 'Yen Do',
        text1: 'Investment Manager',
        text2: 'Beacon',
    },
    {
        img: QuentinFrecon,
        name: 'Quentin (Quân) Frécon',
        text1: 'Country Manager',
        text2: 'Schoolab',
    },
    {
        img: ChristianSchaefer,
        name: 'Christian Schaefer ',
        text1: "Partner",
        text2: 'Asia Counsel Limited',
    },
    {
        img: DoHung,
        name: 'Do Hung',
        text1: 'CEO, ACCESSTRADE Vietnam Strategic AdvisorStrategic Advisor',
        text2: 'MOG Vietnam',
    },
    {
        img: DoTuanAnh,
        name: 'Do Tuan Anh',
        text1: 'Founder & Chairman',
        text2: 'Appota',
    },
    {
        img: JonahLevey,
        name: 'Jonah Levey',
        text1: 'Board member',
        text2: 'Dreamplex Co-founder and Executive Board Member, FlexOS',
    },
    {
        img: DavidSalt,
        name: 'David Salt',
        text1: 'Director of Enterprise Services',
        text2: 'LOGIVAN',
    },
    {
        img: JonMyers,
        name: 'Jon Myers',
        text1: 'Co-Founder',
        text2: 'Design & Marketing PowerTrade',
    },
]

const slider3 = [
    {
        img: JimmyHTran,
        name: 'Jimmy H. Tran',
        text1: 'Vice Chairman of The Board',
        text2: 'Galaxy Digital Holdings',
    },
    {
        img: VietNguen,
        name: 'Viet Nguyen',
        text1: 'Head of Technology Investment',
        text2: 'Vietnam Investments Group',
    },
    {
        img: DungTran,
        name: 'Dung Tran',
        text1: 'CEO & Founder',
        text2: 'MOG',
    },
    {
        img: NiraanDeSilva,
        name: 'Niraan De Silva',
        text1: 'Group CEO & Board Member',
        text2: 'VNLIFE',
    },
    {
        img: NgonPham,
        name: 'Ngon Pham',
        text1: 'Co-founder & CEO',
        text2: 'Ahamove',
    },
    {
        img: TonyDo,
        name: 'Thong (Tony) Do',
        text1: 'Founder & CEO',
        text2: 'Palexy',
    },
    {
        img: ToLinhTruong,
        name: 'To Linh Truong',
        text1: 'Director of Ecommerce, YODY Fashion Former Vice CEO',
        text2: 'iCheck Corporation',
    },
    {
        img: HuyShayneNghiem,
        name: 'Huy (Shayne) Nghiem',
        text1: 'Founder',
        text2: 'Finhay',
    },
    {
        img: HoangEricNguyen,
        name: 'Hoang (Eric) Nguyen',
        text1: 'CEO & Founder',
        text2: 'VinHMS',
    },
    {
        img: QuanTruong,
        name: 'Quan Truong',
        text1: 'CEO & Founder',
        text2: 'OpenCommerce Group',
    },
    {
        img: MyTran,
        name: 'My Tran',
        text1: 'Vice President',
        text2: 'Jungle Ventures',
    },
    {
        img: PhuongTran,
        name: 'Phuong Tran',
        text1: 'Principal',
        text2: 'Wavemaker Partners',
    },
]

const slider4 = [
    {
        img: NamLe,
        name: 'Nam Le',
        text1: 'Principal',
        text2: 'Touchstone Partners',
    },
    {
        img: NgocDiepNguyen,
        name: 'Ngoc Diep Nguyen',
        text1: 'CEO&Founder',
        text2: 'VNP Group',
    },
    {
        img: TuanNguyen,
        name: 'Tuan Nguyen',
        text1: 'CEO',
        text2: 'CyberAgent Capital Vietnam',
    },
    {
        img: DzungHoang,
        name: 'Dzung Hoang',
        text1: 'Country Director of Vietnam',
        text2: 'Genesia Ventures',
    },
    {
        img: RossMacleod,
        name: 'Ross Macleod',
        text1: 'Partner',
        text2: 'Asia Counsel Limited',
    },
    {
        img: DungPham,
        name: 'Dung Phan',
        text1: 'Legal Counsel',
        text2: 'VinaCapital Fund Management',
    },
    {
        img: PhamMinhToan,
        name: 'Pham Minh Toan',
        text1: 'Founder & CEO',
        text2: 'Time Universal & VietFest',
    },
    {
        img: ThangTran,
        name: 'Thang Tran',
        text1: 'Co-Founder & CEO',
        text2: 'Clevai',
    },
    {
        img: ThanhVu,
        name: 'Thanh Vu',
        text1: 'Community Relation Managers',
        text2: 'Tan Hiep Phat',
    },
    {
        img: TruongNguyen,
        name: 'Truong Nguyen',
        text1: 'Co-Founder',
        text2: 'Ahamove',
    },
    {
        img: HaLam,
        name: 'Ha Lam',
        text1: 'Co-Founder of Triip.me',
        text2: undefined,
    },
    {
        img: DucAnhTrinh,
        name: 'Duc Anh Trinh',
        text1: 'Partner',
        text2: 'Velocity Ventures Vietnam',
    },
]

const slider5 = [
    {
        img: HajimeHotta,
        name: 'Hajime Hotta',
        text1: 'Co-Founder, ',
        text2: 'Cinnamon AI Labs',
    },
    {
        img: CharlesLee,
        name: 'Charles Lee',
        text1: 'Co-Founder',
        text2: 'CoderSchool',
    },
    {
        img: RyanGalloway,
        name: 'Ryan Galloway',
        text1: 'Country Director',
        text2: 'Funding Societies Vietnam Head of Capital Markets, Homebase',
    },
    {
        img: BobbyLiu,
        name: 'Bobby Liu',
        text1: 'Director-EIR',
        text2: 'Touchstone Partners',
    },
]

export const MentorsData = [...slider1, ...slider2, ...slider3, ...slider4, ...slider5];