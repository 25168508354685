import { Link, NavLink } from "react-router-dom";
import { ImageAssets } from "../assets";
import { BtnApplyBatch9 } from "../components/btn-apply-battch-9";
import burger from "../assets/burger-menu.svg";
import { useState } from "react";

export function HeaderLayout() {
  const [active, setActive] = useState<boolean>(false);
  const menus = [
    { link: "https://fi.co/join", title: "2023 Program", target: "_blank" },
    { link: "/#FAQ", title: "FAQ", target: "_self" },
    { link: "/#Alumni", title: "Alumni", target: "_self" },
    { link: "/#Network", title: "Investors Network", target: "_self" },
    { link: "/team", title: "Team", target: "_self" },
  ];

  return (
    <div className="container mx-auto">
      <header className="flex flex-wrap items-center justify-between relative">
        <Link to={"/"} className="my-[14px]">
          <img
            src={ImageAssets.logo}
            alt="TFI TECH Founder Institute"
            className="w-[173px] h-[60px] object-contain block"
          />
        </Link>
        <nav id="sidebar" className=" gap-x-[35px] items-center justify-center my-[14px] hidden lg:inline-flex">
          {menus.map((i, index) => {
            return (
              <NavLink
                key={`nav-${index}-${i.title}`}
                to={i.link}
                target={i.target}
                className={"font-medium text-base text-[#8B8B8B]"}
              >
                {i.title}
              </NavLink>
            );
          })}
        </nav>
        <BtnApplyBatch9 style="hidden lg:block" />

        <button
          onClick={() => {
            setActive(!active);
          }}
          className={`w-8 h-8 p-1 rounded-md block lg:hidden ${active ? "bg-slate-200" : "bg-white"}`}
        >
          <img src={burger} alt="Navigation" />
        </button>

        <nav
          id="sidebar-menu"
          className={`overflow-hidden lg:hidden inline-flex gap-x-[35px] items-center justify-center my-[14px] flex-col z-10 left-0 right-0 border-t-2 p-4 shadow-slate-200 shadow-lg absolute top-full bg-white ${
            active ? "top-full opacity-1 " : "-top-[1000px] opacity-0"
          } transition-all duration-600`}
        >
          {menus.map((i, index) => {
            return (
              <NavLink
                key={`nav-${index}-${i.title}`}
                to={i.link}
                target={i.target}
                className={"font-medium text-base text-[#8B8B8B] border-b py-2 w-full"}
              >
                {i.title}
              </NavLink>
            );
          })}

          <BtnApplyBatch9 />
        </nav>
      </header>
    </div>
  );
}
