import { ImageNetworks2 } from "../assets/networks2";


const name = [
    `Insignia Ventures Partners`,
    `Altara Ventures`,
    `KK Fund`,
    `IDG Vietnam`,
    `VinaCapital Ventures`,
    `Touchstone`,
    `Sequoia Capital`,
    `Lightspeed`,
    `Do Ventures`,
    `Plug and Play`,
    `Monk's Hill Ventures`,
    `Ascend Vietnam Ventures`,
    `Openspace Ventures`,
    `GGV Capital`,
    `VIGroup`,
    `Patamar Capital`,
    `Gobi Partners`,
    `Golden Gate Venture`,
    `Saison Capital`,
    `Access Ventures`,
    `Phoenix Holdings`,
    `Softbank Vision Fund`,
    `Velocity`,
    `Wavemaker`,
    `WTP Capital`,
    `Viisa`,
    `Venturra`,
    `CyberAgent Capital`,
    `Infinity Venture -> Headline`,
    `Antler`,
    `500 Startups -> 500 Global`,
    `Genesia Ventures`,
    `Betatron Venture Group`,
    `Strive`,
    `MDI Ventures`,
    `BAce Capital`,
    `Endeavor Vietnam`,
    `Zventure`,
    `BurdaPrincipal Investment`,
    `Qualgro`,
    `Presidio Ventures (belongs to Sumitomo Corporation) `,
    `Ondine Capital`,
    `Mistletoe`,
    `AppWorks`,
    `B Capital Group`,
    `Febe Ventures`,
    `ThinkZone`,
    `Aucfan`,
    `Leo Capital`,
    `Addventure`,
    `Intervest `,
    `SeaX Ventures`,
    `Creative Ventures`,
    `SCB 10X`,
    `Koru`,
    `SV Investment`,
    `Qiming Venture Partners`,
    `SIG Venture Capital`,
    `Spiral Ventures`,
    `White Star Capital`,
    `Orion Venture Partners `,
    `SparkLabs Tapei`,
    `Ace Capital`,
    `China Accelerator`,
    `Bon Angels`,
    `Xpdite Capital`,
    `Mynavi`,
    `Big Idea Ventures`,
    `Beenext`,
    `Eight Roads`,
    `Beacon Fund`,
    `Coloplnext`,
    `Atlast Ventures`,
    `Next100 Ventures`,
    `Vectr`,
    `Amasia`,
    `Quest Ventures`,
    `ESP Capital`,
    `Investible`,
    `Taurus Ventures`,
    `Hive Ventures`,
    `ByteDance`,
    `Siri Ventures`,
    `Zino Ventures`,
    `Legend Capital`,
    `Trive`,
    `SOSV`,
    `Cento Ventures`,
    `MOX `,
    `ATM Capital`,
    `Momentum Ventures ( belongs to SMRT Corporation Ltd.)`,
    `WI Harper`,
    `Elev8`,
    `TNBAura`,
    `Quona Capital`,
    `Amand Ventures`,
    `Sema Translink Investment`,
    `Kasikorn Vision`,
];

const link = [
    `https://www.insignia.vc/`,
    `https://altaraventures.com/`,
    `https://www.kkfund.co/`,
    `https://idgvv.com.vn/en/`,
    `https://ventures.vinacapital.com/`,
    `https://touchstone.vc/`,
    `https://www.sequoiacap.com/`,
    `https://lsvp.com/`,
    `https://doventures.vc/vi`,
    `https://www.plugandplaytechcenter.com/`,
    `https://www.monkshill.com/`,
    `https://ascendvietnam.com/?lang=vi`,
    `https://www.openspace.vc/`,
    `https://www.ggvc.com/`,
    `https://vigroup.com/vn/index.htm`,
    `https://patamar.com/`,
    `https://gobi.vc/`,
    `https://www.goldengate.vc/`,
    `https://www.saisoncapital.com/`,
    `https://www.accessvc.co/`,
    `https://www.linkedin.com/company/phoenix-holdings-private-investment-office/posts/?feedView=all`,
    `https://visionfund.com/`,
    `https://www.velocityventures.co/`,
    `https://wavemaker.vc/`,
    `http://www.wtpcapital.com/`,
    `https://www.viisa.vn/`,
    `https://www.venturra.com/`,
    `https://www.cyberagentcapital.com/en/`,
    `https://headline.com/`,
    `https://www.antler.co/`,
    `https://500.co/`,
    `https://www.genesiaventures.com/en/top-en/`,
    `https://www.betatron.co/`,
    `https://strive.vc/en/`,
    `https://mdi.vc/`,
    `https://www.bace.vc/`,
    `https://endeavorvietnam.org/`,
    `https://zvc.vc/`,
    `https://www.burdaprincipalinvestments.com/`,
    `https://qualgro.com/`,
    `https://presidio-ventures.com/`,
    `https://www.ondinecap.com/`,
    `https://mistletoe.co/`,
    `https://appworks.tw/`,
    `https://www.bcapgroup.com/`,
    `https://www.febe.vc/`,
    `https://thinkzone.vn/`,
    `https://aucfan.co.jp/en/`,
    `https://leo.capital/`,
    `https://www.addventures.co.th/`,
    `http://www.intervest.co.kr/en`,
    `https://seaxventures.vc/`,
    `https://creativeventures.vc/`,
    `https://www.scb10x.com/`,
    `https://www.korupartners.com/`,
    `http://www.svinvestment.co.kr/`,
    `https://www.qimingvc.com/en`,
    `https://sig-asiavc.com/`,
    `https://spiral-ventures.com/?lang=en`,
    `https://whitestarcapital.com/`,
    `https://orionventurepartners.com/`,
    `https://www.sparklabstaipei.com/`,
    `http://www.theacecapital.com/`,
    `https://chinaccelerator.com/`,
    `http://en.bonangels.net/`,
    `https://www.xpditecapital.com/`,
    `https://www.mynavi.jp/eng/`,
    `https://bigideaventures.com/`,
    `https://www.beenext.com/`,
    `https://eightroads.com/en/`,
    `https://beaconfund.com/`,
    `https://www.coloplnext.co.jp/`,
    `http://atlas.ventures/`,
    `https://next100.vc/`,
    `https://vectr.co/`,
    `https://amasia.vc/`,
    `https://www.questventures.com/`,
    `https://www.espcapital.net/`,
    `https://www.investible.com/`,
    `http://www.taurus.vc/`,
    `https://www.hiveventures.io/`,
    `https://www.bytedance.com/en/`,
    `www.siriventures.vc/`,
    `https://www.zino.co.nz/`,
    `https://www.legendcapital.com.cn/index_en.aspx?id=0`,
    `https://www.trive.vc/`,
    `https://sosv.com/`,
    `https://www.cento.vc/`,
    `https://mobileonlyx.com/`,
    `http://atmcapital.com/`,
    `https://momentumvc.sg/`,
    `https://wiharper.com/`,
    `https://elev8.vc/`,
    `https://tnbaura.vc/`,
    `https://quona.com/`,
    `https://amand.ventures/`,
    `https://www.translink.kr/`,
    `https://www.kasikornvision.com/en/home`,
    `https://www.jungle.vc/`,

]

export const NetworksData2 = name.map((i, index) => {
    return { name: i, link: link[index], image: ImageNetworks2[index] };
}).filter((i) => { return typeof i.image !== 'undefined' && i.image !== null });
